<template>
    <b-modal
    id="success-appointment-modal"
    hide-header
    hide-footer
    centered
    @hidden="goAttendance"
    >

        <div class="header">
            <div class="header-content">
                <p>Consulta salva com sucesso</p>
                <Close class="close-svg" @click="goAttendance" />
            </div>
        </div>

        <div class="body">
            <div class="body-content">
                <CheckModal class="check-svg" />
                <p>Consulta salva com sucesso.</p>
                <div class="buttons">
                    <button class="button" id="view-appointment" @click="goAttendance">Ver consulta salva</button>
                    <button class="button" id="view-profile" @click="goPatient">Ver perfil do paciente</button>
                </div>
            </div>
        </div>
    </b-modal>

</template>

<script>
import CheckModal from '../../assets/icons/check-modal.svg'
import Close from '../../assets/icons/close.svg'

export default {
    name: 'success-appointment-modal',
    components: {
        CheckModal,
        Close
    },
    props: {
        goAttendance: { type: Function, required: true },
        goPatient: { type: Function, required: true }
    }
}
</script>

<style lang="scss">
#success-appointment-modal {
    .modal-content {
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, 0);
        max-width: 674px;

        .modal-body {
            padding: 0;
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: var(--type-active);

            .header {
                border-bottom: 1px solid var(--neutral-200);

                .header-content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin: 24px;

                    .close-svg {
                        height: 24px;
                        width: 24px;
                        fill: var(--neutral-500);
                    }
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .body-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 56px 0 56px 0;

                    p {
                        margin: 40px 0;
                    }

                    .check-svg {
                        height: 96px;
                        width: 96px;
                    }

                    .buttons {
                        display: flex;
                        flex-direction: column;

                        .button {
                            width: 168px;
                            height: 40px;
                        }

                        #view-appointment {
                            color: #FFFFFF;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 24px;
                            background: #305BF2;
                            border-radius: 8px;
                        } 

                        #view-profile {
                            color: #305BF2;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }

            }
        }
    }
}

</style>